import React from 'react'
import { graphql, Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { Box, Heading, SimpleGrid, Text } from '@chakra-ui/react'

import Layout from '../components/layout/Layout'
import Container from '../components/layout/Container'
import Section from '../components/layout/Section'
import SEO from '../components/elements/SEO'
import Hero from '../components/elements/Hero'

const EventsPage = ({ data }) => {
  const page = data.prismicEventspage

  return (
    <Layout>
      <SEO
        meta_title={page.data.meta_title}
        meta_description={page.data.meta_description}
      />

      <Hero>
        <BackgroundImage
          Tag="div"
          fluid={page.data.hero_image.fluid}
          css={`
            padding-top: 180px;
          `}
        >
          <Container py={['12em', '12em', '16em']} />
        </BackgroundImage>
      </Hero>

      <Section id="events" bg="gray.50">
        <Container>
          <Box width={['full']} mb={12}>
            <Heading as="h1" mb={12}>
              {page.data.title.text}
            </Heading>
            <Text
              dangerouslySetInnerHTML={{
                __html: page.data.content.html,
              }}
              width={['full', 'full', 3 / 4]}
            />
          </Box>
          <SimpleGrid columns={[1, 1, 2, 3]} spacing={9}>
            {data.allPrismicEvent.edges.map(({ node: event }) => (
              <Box
                key={event.id}
                as="article"
                bg="white"
                p={6}
                borderColor="gray.100"
                borderWidth={1}
              >
                <p
                  css={`
                    margin-bottom: 0;
                  `}
                >
                  {event.data.event_date}
                </p>
                <Box as="h3">{event.data.title.text}</Box>
                <Link to={`/events/${event.uid}`}>Read More →</Link>
              </Box>
            ))}
          </SimpleGrid>
        </Container>
      </Section>
    </Layout>
  )
}

export default EventsPage

export const query = graphql`
  query EventsPage {
    prismicEventspage {
      data {
        meta_description
        meta_title
        content {
          html
        }
        hero_image {
          url
          fluid(maxWidth: 1500) {
            ...GatsbyPrismicImageFluid
          }
        }
        title {
          text
        }
      }
    }
    allPrismicEvent(
      sort: { fields: data___event_date, order: DESC }
      limit: 1000
    ) {
      edges {
        node {
          data {
            content {
              html
            }
            event_date(formatString: "MMM Do, YYYY")
            title {
              text
            }
          }
          id
          uid
        }
      }
    }
  }
`
